import React, { Component } from 'react';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip } from 'reactstrap';
import AuthFunctions from '../services/auth'

export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        this.state = {
            userName: '',
            password: ''
        };
    }

    async handleLogin() {
        var result = await AuthFunctions.login(this.state.userName, this.state.password);
        if (result.status == "success" && result.token != null) {
            localStorage.setItem('token', result.token);
            window.location.href = '/';
        }
    }
  
    render () {
        return(
            <div className="login-wrapper">
                <Container style={{width:'400px'}}>
                    <Row>
                        <Col>
                            <h1>Please Log In</h1>
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Input type="text" onChange={(e) => this.setState({ userName: e.target.value })} placeholder="Username" />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Input type="password" onChange={(e) => this.setState({ password: e.target.value })} placeholder="Password" />
                        </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <Col>
                            <Button color='primary'
                                className='standardButton'
                                onClick={() => this.handleLogin()}>
                                Submit
                            </Button>
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}

export default Login;