import React, { useEffect, Component } from 'react';
import ApiFunctions from './services/api';
import AuthFunctions from './services/auth';

import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip, Card, CardHeader, CardBody } from 'reactstrap';

export class History extends Component {
  static displayName = History.name;

  constructor(props) {
    super(props);

    this.state = {
      history: [],
      timerId: null,
      selectedTag: null,
    }

      var authToken = AuthFunctions.getAuthToken();
      if (authToken == null) {
          window.location.href = '/login';
      }

      this.navigateBack = this.navigateBack.bind(this);
  }

  navigateBack(e) {
    e.preventDefault();
    this.props.history.push('/');
  }

  async getAssetHistory(tag) {
    this.setState({selectedTag: tag});
    var history = await ApiFunctions.getAssetHistoryAsync(tag);

    var assetHistory = [];

    if (history != null) {
      history.forEach(h => {
        assetHistory.push(h.record)
      });

      assetHistory.sort(function (a, b) {
        var dateA = new Date(a.Timestamp);
        var dateB = new Date(b.Timestamp);

        return dateB - dateA;
      });

      assetHistory.reverse();

      this.setState({history: assetHistory});
    }
  }

async componentDidMount() {
    if(this.props.location.state !== undefined) {
        var selectedTag = this.props.location.state.selectedTag;

        this.getAssetHistory(selectedTag);
    }
    else {
        this.props.history.push('/');
    }
}

  render () {
    const assetCardRender = this.state.history.map((asset, i) => {
      return (
        <Card 
          className='my-2' 
          color='secondary' 
          outline>
          <CardHeader style={{fontWeight:'bold'}}><a href="#" onClick={(e) => {this.getAssetHistory(asset.Tag); e.preventDefault();}}>{asset.Tag}</a> - {asset.Status} [Source: {asset.From}, Destination: {asset.To}]</CardHeader>
            <CardBody>
              <Container style={{height:'100%'}}>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'25%', fontWeight:'bold'}}>Tag</Col>
                  <Col style={{width:'25%', fontWeight:'bold'}}>Commodity</Col>
                  <Col style={{width:'2%', fontWeight:'bold'}}>Date</Col>
                  <Col style={{width:'25%', fontWeight:'bold', textAlign:'right'}}>Net Weight</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'25%'}}><a href="#" onClick={(e) => {this.getAssetHistory(asset.Tag); e.preventDefault();}}>{asset.Tag}</a></Col>
                  <Col style={{width:'25%'}}>{asset.Commodity}</Col>
                  <Col style={{width:'25%'}}>{asset.TransDate}</Col>
                  <Col style={{width:'25%', textAlign:'right'}}>{asset.Weight}</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'50%', fontWeight:'bold'}}>
                    <Card className='my-2' color='secondary' outline>
                      <CardHeader>Sourced From</CardHeader>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col style={{width:'50%'}}>Tag</Col>
                            <Col style={{width:'50%',textAlign:'right'}}>Net Weight</Col>
                          </Row>
                          {asset.Sources.map((source, j) => {
                            return <Row key={j} style={{whiteSpace: "nowrap"}}>
                              <Col style={{width:'50%', fontWeight:'normal'}}><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></Col>
                              <Col style={{width:'50%', textAlign:'right', fontWeight:'normal'}}>{source.Weight}</Col>
                            </Row>
                          })}                        
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col style={{width:'50%', fontWeight:'bold'}}>
                    <Card className='my-2' color='secondary' outline>
                      <CardHeader>Used By</CardHeader>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col style={{width:'50%'}}>Tag</Col>
                            <Col style={{width:'50%',textAlign:'right'}}>Net Weight</Col>
                          </Row>
                          {asset.UsedBy.map((source, j) => {
                            return <Row key={j} style={{whiteSpace: "nowrap"}}>
                              <Col style={{width:'50%', fontWeight:'normal'}}><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></Col>
                              <Col style={{width:'50%', textAlign:'right', fontWeight:'normal'}}>{source.Weight}</Col>
                            </Row>
                          })}                        
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </CardBody>
        </Card>
      )
    });


    return (
      <div className='page-content'>
        <h3><a href='#' onClick={(e) => this.navigateBack(e)}>Active Tag List</a></h3>

        <Container style={{marginTop: '20px', paddingBottom: '50px'}}>
          <Row>
            <Col>
              <h3 style={{marginTop: '20px'}}>{this.state.selectedTag} - Transaction History</h3>
              <Container>
                {assetCardRender}
              </Container>
            </Col>
          </Row>
        </Container>        
      </div>
    );
  }
}
