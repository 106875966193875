import React, { useEffect, Component } from 'react';
import ApiFunctions from './services/api';
import AuthFunctions from './services/auth';
import { Container, Row, Col, Input, Button, Table, Spinner, UncontrolledTooltip, Card, CardHeader, CardBody } from 'reactstrap';
import { FaRegTrashAlt } from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import { FaPlus, FaMinus, FaEdit } from 'react-icons/fa';
//import Select from 'react-select';
import Select from 'react-dropdown-select';
import Moment from 'moment';

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);

    this.state = {
      assets: [
        // {Commodity:"AC3569XDU",Division:1,From:"S1-SUPM09",Sources:[],Status:"Received",Tag:"60165269",Timestamp:"2022-01-07 12:00:00",To:"YD03",Trailer:"488909",TransDate:"01/07/22",UsedBy:[],Weight:0},
        // {Commodity:"AC3569XDL",Division:1,From:"YD03",Sources:[{Tag:"60165269",Weight:39220}],Status:"Shipped",Tag:"60174589",Timestamp:"2022-01-10 12:00:00",To:"S1-SHAPMA",Trailer:"488909",TransDate:"01/10/22",UsedBy:[],Weight:0},
        // {Commodity:"AC3569XDL",Division:4,From:"YD03",Sources:[{Tag:"60174589",Weight:39220}],Status:"Received",Tag:"SM3569XD-357",Timestamp:"2022-01-11 12:00:00",To:"S1-SHAPMA",Trailer:"488909",TransDate:"01/11/22",UsedBy:[],Weight:0},
        // {Commodity:"SI71XXXXRL",Division:4,From:"YD54",Sources:[{Tag:"SM3569XD-357",Weight:39220}],Status:"Shipped",Tag:"9743",Timestamp:"2022-01-20 12:00:00",To:"S1-PACT00",Trailer:"428394",TransDate:"01/20/22",UsedBy:[],Weight:25493}
      ],
      history: [],
      divisions: [],
      locationList: [],
      tagList: [],
      availableTags: [],
      tagTypeList: [ {value: 'Production', label: 'Production'}, {value: 'Received', label:'Received'}, {value: 'Shipped', label: 'Shipped'} ],
      commodityList: [{value: 'AC3569XDU', label: 'AC3569XDU'}, {value: 'AC3569XDL', label: 'AC3569XDL'}, {value: 'SI71XXXXRL', label: 'SI71XXXXRL'}],
      siteList: [{value: 'YD01', label: 'YD01'},{value: 'YD02', label: 'YD02'},{value: 'YD03', label: 'YD03'},{value: 'YD54', label: 'YD54'},{value: 'S1-SHAPMA', label: 'S1-SHAPMA'},{value: 'S1-PACT00', label: 'S1-PACT00'},{value: 'S1-SUPM09', label: 'S1-SUPM09'}],
      timerId: null,
      selectedTag: {Commodity:"",Division:0,From:"",Sources:[],Status:"",Tag:"",Timestamp:"",To:"",Trailer:"",TransDate:"",UsedBy:[],Weight:0},
      showAdd: false,
      showUpdate: false,
      showMove: false,
      showDelete: false,
    }

    var authToken = AuthFunctions.getAuthToken();
    if (authToken == null) {
        window.location.href = '/login';
    }
  }

  async addNewSource() {
    var selectedTag = this.state.selectedTag;
    selectedTag.Sources.push({Tag: '', Weight: 0});
    this.setState({selectedTag: selectedTag});
  }

  async updateStatus(values) {
    if (values != null && values.length > 0) {
      var value = values[0].value;
      var tag = this.state.selectedTag;
      tag.Status = value;
      this.setState({selectedTag: tag});
      }
  }

  async updateTag(e) {
      var tag = this.state.selectedTag;
      tag.Tag = e.target.value;
      this.setState({selectedTag: tag});
  }

  async updateCommodity(values) {
    if (values != null && values.length > 0) {
      var value = values[0].value;
      var tag = this.state.selectedTag;
      tag.Commodity = value;
      this.setState({selectedTag: tag});
      }
  }

  async updateFrom(values) {
    if (values != null && values.length > 0) {
      var value = values[0].value;
      var tag = this.state.selectedTag;
      tag.From = value;
      this.setState({selectedTag: tag});
    }
  }

  async updateTo(values) {
    if (values != null && values.length > 0) {
      var value = values[0].value;
      var tag = this.state.selectedTag;
      tag.To = value;
      this.setState({selectedTag: tag});
    }
  }
 
  async updateDate(e) {
    var tag = this.state.selectedTag;
    tag.TransDate = e.target.value;
    this.setState({selectedTag: tag});
  }

  async updateWeight(e) {
    var tag = this.state.selectedTag;

    if (isNaN(e.target.value) || e.target.value.trim() === '') {
      tag.Weight = 0;
    } else {
      tag.Weight = Number.parseInt(e.target.value);
    }
    this.setState({selectedTag: tag});
  }
  
  async receiveShipment(){
    this.setState({showAdd: true, showUpdate: false});
      // var asset = {Commodity:"AC3569XDU",Division:1,From:"S1-SUPM09",Sources:[],Status:"Received",Tag:"60165269",Timestamp:"2022-01-07 12:00:00",To:"YD03",Trailer:"488909",TransDate:"01/07/22",UsedBy:[],Weight:39220}
      // var response = await ApiFunctions.postAssetAddAsync(asset);

      // if (response != null) {
      //   console.log(response);
      // }
  }

  async processShipment() {
    var asset = {Commodity:"AC3569XDL",Division:1,From:"YD03",Sources:[{Tag:"60165269",Weight:39220}],Status:"Production",Tag:"60174589",Timestamp:"2022-01-08 12:00:00",To:"YD03",Trailer:"488909",TransDate:"01/08/22",UsedBy:[],Weight:39220}
    var response = await ApiFunctions.postAssetAddAsync(asset);

    if (response != null) {
      console.log(response);
    }
  }

  async shipLoad() {
    var asset = {Commodity:"AC3569XDL",Division:1,From:"YD03",Sources:[{Tag:"60165269",Weight:39220}],Status:"Shipped",Tag:"60174589",Timestamp:"2022-01-10 12:00:00",To:"S1-SHAPMA",Trailer:"488909",TransDate:"01/10/22",UsedBy:[],Weight:39220}
    var response = await ApiFunctions.postAssetUpdateAsync(asset);

    if (response != null) {
      console.log(response);
    }
  }

  async receiveLoad() {
    var asset = {Commodity:"AC3569XDL",Division:4,From:"YD03",Sources:[{Tag:"60174589",Weight:39220}],Status:"Received",Tag:"SM3569XD-357",Timestamp:"2022-01-11 12:00:00",To:"S1-SHAPMA",Trailer:"488909",TransDate:"01/11/22",UsedBy:[],Weight:39220}
    var response = await ApiFunctions.postAssetAddAsync(asset);

    if (response != null) {
      console.log(response);
    }
  }

  async processHeat() {
    var asset = {Commodity:"SI71XXXXRL",Division:4,From:"S1-SHAPMA",Sources:[{Tag:"SM3569XD-357",Weight:39220}],Status:"Production",Tag:"9743",Timestamp:"2022-01-15 12:00:00",To:"YD54",Trailer:" ",TransDate:"01/15/22",UsedBy:[],Weight:25493}
    var response = await ApiFunctions.postAssetAddAsync(asset);

    if (response != null) {
      console.log(response);
    }
  }

  async shipHeat() {
    var asset = {Commodity:"SI71XXXXRL",Division:4,From:"YD54",Sources:[{Tag:"SM3569XD-357",Weight:39220}],Status:"Shipped",Tag:"9743",Timestamp:"2022-01-20 12:00:00",To:"S1-PACT00",Trailer:"428394",TransDate:"01/20/22",UsedBy:[],Weight:25493}
    var response = await ApiFunctions.postAssetUpdateAsync(asset);

    if (response != null) {
      console.log(response);
    }
  }

  async getAssetHistory(tag) {
    this.props.history.push({
      pathname: '/history',
      state: { selectedTag: tag }
    });
  }

  async handleClose() {
    this.setState({showAdd: false, showUpdate: false, showDelete: false, selectedTag: {Commodity:"",Division:0,From:"",Sources:[],Status:"",Tag:"",Timestamp:"",To:"",Trailer:"",TransDate:"",UsedBy:[],Weight:0}});
  }

  async addAsset() {
    //var assets = this.state.assets;
    var date = new Date();
    var asset = this.state.selectedTag;
    var response = await ApiFunctions.postAssetAddAsync({
      Commodity:asset.Commodity,
      Division:4,
      From:asset.From,
      Sources:asset.Sources,
      Status:asset.Status,
      Tag:asset.Tag,
      Timestamp:Moment(date).format('YYYY-MM-dd H:mm:ss'),
      To:asset.To,
      Trailer:"488909",
      TransDate:asset.TransDate,
      UsedBy:[],
      Weight:asset.Weight
    });

    if (response != null) {
      console.log(response);
    }
    this.setState({selectedTag: {Commodity:"",Division:0,From:"",Sources:[],Status:"",Tag:"",Timestamp:"",To:"",Trailer:"",TransDate:"",UsedBy:[],Weight:0}});
    //this.setState({assets: assets});
    this.setState({showAdd: false, showUpdate: false});
  }

  async getAssets() {
    var assets = await ApiFunctions.getAllAssetsAsync();
    //var assets = this.state.assets;

    if (assets != null) {
      let locations = [];
      let tags = [];
      let tagNames = [];

      assets.sort(function (a, b) {
        var dateA = new Date(a.Timestamp);
        var dateB = new Date(b.Timestamp);

        return dateB - dateA;
      });

      assets.reverse();

      assets.forEach(a => {
        locations.push({ Location: a.To });
        locations.push({ Location: a.From }); 
        tags.push({ Tag: a.Tag, Weight: a.Weight });
        tagNames.push({ value: a.Tag, label: a.Tag });
      });

      let uniqLocation = [...new Set(locations)].sort();
      let uniqTags = [...new Set(tags)].sort();
      let uniqTagNames = [...new Set(tagNames)].sort();

      this.setState({assets: assets, locationList: uniqLocation, tagList: uniqTags, availableTags: uniqTagNames});
    }
}

async componentWillUnmount() {
  clearInterval(this.state.timerId);
}

async componentDidMount() {
    this.getAssets();
    var timerId = setInterval(() => {
      this.getAssets();
    }, 5000);
    this.setState({timerId: timerId});
}

async handleDeleteTag(asset) {
  this.setState({selectedTag: asset});
  this.setState({showAdd: false, showUpdate: false, showDelete: true});
}

async deleteAsset() {
  var tag = this.state.selectedTag.Tag;
  var result = ApiFunctions.getAssetDeleteAsync(tag);
  console.log(result);
}

async updateAsset() {
  var asset = this.state.selectedTag;
  var response = await ApiFunctions.postAssetUpdateAsync(asset);

  if (response != null) {
    console.log(response);
  }

  this.setState({selectedTag: {Commodity:"",Division:0,From:"",Sources:[],Status:"",Tag:"",Timestamp:"",To:"",Trailer:"",TransDate:"",UsedBy:[],Weight:0}});
  this.setState({showAdd: false, showUpdate: false});
}

async handleUpdateTag(asset) {
  this.setState({selectedTag: asset, showUpdate: true, showAdd: false})
}

async getSelectedSite(site) {
  var sites = this.state.siteList;
  var selected = sites.filter(function (el) {
    return el.value === site;
  });
  return selected;
}

  render () {
    const assetCardRender = this.state.assets.map((asset, i) => {
      return (
        <Card 
          className='my-2' 
          color='secondary' 
          outline>
          <CardHeader style={{fontWeight:'bold'}}>
            <FaEdit style={{color: 'blue', marginRight: '10px'}} onClick={(e) => this.handleUpdateTag(asset)}/>            
            <a href="#" onClick={(e) => {this.getAssetHistory(asset.Tag); e.preventDefault();}}>{asset.Tag}</a> - {asset.Status} [Origin: {asset.From}, Destination: {asset.To}]
            <FaMinus style={{color: 'red', right: '10px', position: 'absolute'}} onClick={(e) => this.handleDeleteTag(asset)}/>            
          </CardHeader>
            <CardBody>
              <Container style={{height:'100%'}}>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'25%', fontWeight:'bold'}}>Tag</Col>
                  <Col style={{width:'25%', fontWeight:'bold'}}>Commodity</Col>
                  <Col style={{width:'2%', fontWeight:'bold'}}>Date</Col>
                  <Col style={{width:'25%', fontWeight:'bold', textAlign:'right'}}>Net Weight</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'25%'}}><a href="#" onClick={(e) => {this.getAssetHistory(asset.Tag); e.preventDefault();}}>{asset.Tag}</a></Col>
                  <Col style={{width:'25%'}}>{asset.Commodity}</Col>
                  <Col style={{width:'25%'}}>{asset.TransDate}</Col>
                  <Col style={{width:'25%', textAlign:'right'}}>{asset.Weight}</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'50%', fontWeight:'bold'}}>
                    <Card className='my-2' color='secondary' outline>
                      <CardHeader>Sourced From</CardHeader>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col style={{width:'50%'}}>Tag</Col>
                            <Col style={{width:'50%',textAlign:'right'}}>Net Weight</Col>
                          </Row>
                          {asset.Sources.map((source, j) => {
                            return <Row key={j} style={{whiteSpace: "nowrap"}}>
                              <Col style={{width:'50%', fontWeight:'normal'}}><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></Col>
                              <Col style={{width:'50%', textAlign:'right', fontWeight:'normal'}}>{source.Weight}</Col>
                            </Row>
                          })}                        
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col style={{width:'50%', fontWeight:'bold'}}>
                    <Card className='my-2' color='secondary' outline>
                      <CardHeader>Used By</CardHeader>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col style={{width:'50%'}}>Tag</Col>
                            <Col style={{width:'50%',textAlign:'right'}}>Net Weight</Col>
                          </Row>
                          {asset.UsedBy.map((source, j) => {
                            return <Row key={j} style={{whiteSpace: "nowrap"}}>
                              <Col style={{width:'50%', fontWeight:'normal'}}><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></Col>
                              <Col style={{width:'50%', textAlign:'right', fontWeight:'normal'}}>{source.Weight}</Col>
                            </Row>
                          })}                        
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </CardBody>
        </Card>
      )
    });

    const historyCardRender = this.state.history.map((asset, i) => {
      return (
        <Card 
          className='my-2' 
          color='secondary' 
          outline>
            <CardHeader>{asset.Tag} - {asset.Status} [Origin: {asset.From}, Destination: {asset.To}]</CardHeader>
            <CardBody>
              <Container style={{height:'100%', fontSize:'0.8rem'}}>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'50%', fontWeight:'bold'}}>Tag</Col>
                  <Col style={{width:'50%', fontWeight:'bold'}}>Commodity</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'50%'}}><a href="#" onClick={(e) => {this.getAssetHistory(asset.Tag); e.preventDefault();}}>{asset.Tag}</a></Col>
                  <Col style={{width:'50%'}}>{asset.Commodity}</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'50%', fontWeight:'bold'}}>Date</Col>
                  <Col style={{width:'50%', fontWeight:'bold'}}>Net Weight</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'25%'}}>{asset.TransDate}</Col>
                  <Col style={{width:'50%'}}>{asset.Weight}</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'50%', fontWeight:'bold'}}>Sourced From</Col>
                  <Col style={{width:'50%', fontWeight:'bold'}}>Used By</Col>
                </Row>
                <Row style={{marginTop:'5px'}}>
                  <Col style={{width:'50%'}}>
                    <table style={{width:'100%'}}>
                      <thead>
                        <th style={{width:'50%'}}>Tag</th>
                        <th style={{width:'50%', textAlign:'right'}}>Net Weight</th>
                      </thead>
                      <tbody>
                      {asset.Sources.map((source, j) => {
                        return <tr key={j} style={{whiteSpace: "nowrap"}}>
                          <td><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></td>
                          <td style={{textAlign:'right'}}>{source.Weight}</td>
                        </tr>
                      })}                        
                      </tbody>
                    </table>
                  </Col>
                  <Col style={{width:'50%'}}>
                    <table style={{width:'100%'}}>
                      <thead>
                        <th style={{width:'50%'}}>Tag</th>
                        <th style={{width:'50%', textAlign:'right'}}>Net Weight</th>
                      </thead>
                      <tbody>
                      {asset.UsedBy.map((usedBy, j) => {
                        return <tr key={j} style={{whiteSpace: "nowrap"}}>
                          <td><a href="#" onClick={(e) => {this.getAssetHistory(usedBy.Tag); e.preventDefault();}}>{usedBy.Tag}</a></td>
                          <td style={{textAlign:'right'}}>{usedBy.Weight}</td>
                        </tr>
                      })}                        
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </Container>
            </CardBody>
        </Card>
      )
    });

    const assetRenderer = this.state.assets.map((asset, i) => {
      return <tr key={i} style={{whiteSpace: "nowrap"}}>
        <td><a href="#" onClick={(e) => {this.getAssetHistory(asset.Tag); e.preventDefault();}}>{asset.Tag}</a></td>
        <td>{asset.Commodity}</td>
        <td>{asset.From}</td>
        <td>{asset.To}</td>
        {/* <Col>{asset.Division}</Col> */}
        <td>{asset.Status}</td>
        {/* <td>{asset.Trailer}</td> */}
        <td>{asset.TransDate}</td>
        <td style={{textAlign:'right'}}>{asset.Weight}</td>
        <td>
          <table>
            <tbody>
          {asset.Sources.map((source, j) => {
            return <tr key={j} style={{whiteSpace: "nowrap"}}>
              <td><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></td>
              <td style={{textAlign:'right'}}>{source.Weight}</td>
            </tr>
          })}
            </tbody>
          </table>
        </td>
        <td>
          <table>
            <tbody>
          {asset.UsedBy.map((source, j) => {
            return <tr key={j} style={{whiteSpace: "nowrap"}}>
              <td><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></td>
              <td style={{textAlign:'right'}}>{source.Weight}</td>
            </tr>
          })}
            </tbody>
          </table>
        </td>
      </tr>
    });

    const historyRenderer = this.state.history.map((asset, i) => {
      return <tr key={i} style={{whiteSpace: "nowrap"}}>
        <td>{asset.Tag}</td>
        <td>{asset.Commodity}</td>
        <td>{asset.From}</td>
        <td>{asset.To}</td>
        {/* <Col>{asset.Division}</Col> */}
        <td>{asset.Status}</td>
        {/* <td>{asset.Trailer}</td> */}
        <td>{asset.TransDate}</td>
        <td style={{textAlign:'right'}}>{asset.Weight}</td>
        <td>
          <table>
            <tbody>
          {asset.Sources.map((source, j) => {
            return <tr key={j} style={{whiteSpace: "nowrap"}}>
              <td><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></td>
              <td style={{textAlign:'right'}}>{source.Weight}</td>
            </tr>
          })}
            </tbody>
          </table>
        </td>
        <td>
          <table>
            <tbody>
          {asset.UsedBy.map((source, j) => {
            return <tr key={j} style={{whiteSpace: "nowrap"}}>
              <td><a href="#" onClick={(e) => {this.getAssetHistory(source.Tag); e.preventDefault();}}>{source.Tag}</a></td>
              <td style={{textAlign:'right'}}>{source.Weight}</td>
            </tr>
          })}
            </tbody>
          </table>
        </td>
      </tr>
    });

    return (
      <div className='page-content'>
        <Container style={{marginTop: '20px'}}>
          <table style={{width: '100%'}}>
            <tr>
              <td>
                <Button
                    color='primary'
                    style={{ width: '250px', height: '42px' }}
                    className='standardButton'
                    onClick={() => this.receiveShipment()}>
                    Create New Tag
                </Button>
              </td>
              <td>
                {/* <Button
                    color='primary'
                    style={{ width: '125px', height: '62px' }}
                    className='standardButton'
                    onClick={() => this.processShipment()}>
                    2. Process Shipment
                </Button> */}
              </td>
              <td>
                {/* <Button
                    color='primary'
                    style={{ width: '125px', height: '48px', height: '62px' }}
                    className='standardButton'
                    onClick={() => this.shipLoad()}>
                    3. Ship Load
                </Button> */}
              </td>
              <td>
                {/* <Button
                    color='primary'
                    style={{ width: '125px', height: '48px', height: '62px' }}
                    className='standardButton'
                    onClick={() => this.receiveLoad()}>
                    4. Receive Load
                </Button> */}
              </td>
              <td>
                {/* <Button
                    color='primary'
                    style={{ width: '125px', height: '48px', height: '62px' }}
                    className='standardButton'
                    onClick={() => this.processHeat()}>
                    5. Process Heat
                </Button> */}
              </td>
              <td>
                {/* <Button
                    color='primary'
                    style={{ width: '125px', height: '48px', height: '62px' }}
                    className='standardButton'
                    onClick={() => this.shipHeat()}>
                    6. Ship Heat
                </Button> */}
              </td>
            </tr>
          </table>
        </Container>
        
        <Container style={{marginTop: '20px', paddingBottom: '50px'}}>
          <Row>
            <Col>
              <h3 style={{marginTop: '20px'}}>Active Tags</h3>
              <Container>
                {assetCardRender}
              </Container>
            </Col>
            {/* <Col>
              <h3 style={{marginTop: '20px'}}>{this.state.selectedTag} - History</h3>
              <Container style={{height:'750px', maxHeight:'750px', overflowY:'scroll'}}>
                {historyCardRender}
              </Container>
            </Col> */}
          </Row>
          

          {/* <table style={{ fontSize: '0.75rem' }}>
            <thead>
            <th style={{width: '100px'}}>Tag</th>
              <th style={{width: '115px'}}>Commodity</th>
              <th style={{width: '75px'}}>From</th>
              <th style={{width: '75px'}}>To</th>
              <th style={{width: '75px'}}>Status</th>
              <th style={{width: '75px'}}>Date</th>
              <th style={{width: '100px'}}>Net Weight</th>
              <th style={{width: '250px'}}>Source(s)</th>
              <th style={{width: '250px'}}>Used By</th>
            </thead>
            <tbody>
              {assetRenderer}
            </tbody>                  
          </table> */}
        </Container>        

        

        {/* <Container style={{marginTop: '20px', marginBottom: '50px'}}>
          <table style={{ fontSize: '0.75rem' }}>
            <thead>
              <th style={{width: '100px'}}>Tag</th>
              <th style={{width: '115px'}}>Commodity</th>
              <th style={{width: '75px'}}>From</th>
              <th style={{width: '75px'}}>To</th>
              <th style={{width: '75px'}}>Status</th>
              <th style={{width: '75px'}}>Date</th>
              <th style={{width: '100px'}}>Net Weight</th>
              <th style={{width: '250px'}}>Source(s)</th>
              <th style={{width: '250px'}}>Used By</th>
            </thead>
            <tbody>
              {historyRenderer}
            </tbody>                  
          </table>
        </Container>         */}

          <Modal show={this.state.showAdd} onHide={(e) => this.handleClose('add')} dialogClassName="inventory-modal">
            <Modal.Header > 
                <Modal.Title>Create New Tag</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                  <Col style={{width: '50%'}}>
                    <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Status</label>
                  </Col>
                </Row>
                <Row style={{marginBottom: 10}}>
                  <Col style={{width: '100%'}}>
                    <Select
                      options={this.state.tagTypeList}
                      values={this.state.tagTypeList.filter(option => option.value === this.state.selectedTag.Status)}
                      onChange={(value) => this.updateStatus(value)} />
                  </Col>
                </Row>
                <Row>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Tag</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Commodity</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                    <input type="text" className="form-control" style={{ fontSize: '0.8rem' }} value={this.state.selectedTag.Tag} onChange={(e) => this.updateTag(e)} />
                    </Col>
                    <Col style={{width: '50%'}}>
                      <Select
                        options={this.state.commodityList}
                        values={this.state.commodityList.filter(option => option.value === this.state.selectedTag.Commodity)}
                        onChange={(value) => this.updateCommodity(value)} />
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Origin</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Destination</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                    <Select
                      options={this.state.siteList}
                      values={this.state.siteList.filter(option => option.value === this.state.selectedTag.From)}
                      onChange={(value) => this.updateFrom(value)} />
                    </Col>
                    <Col style={{width: '50%'}}>
                    <Select
                      options={this.state.siteList}
                      values={this.state.siteList.filter(option => option.value === this.state.selectedTag.To)}
                      onChange={(value) => this.updateTo(value)} />
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Date</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Net Weight (lbs)</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                      <input type="date" className="form-control" style={{ fontSize: '0.8rem' }} value={this.state.selectedTag.TransDate} onChange={(e) => this.updateDate(e)}/>
                    </Col>
                    <Col style={{width: '50%'}}>
                      <input type="text" className="form-control" style={{ fontSize: '0.8rem' }} value={this.state.selectedTag.Weight} onChange={(e) => this.updateWeight(e)}/>
                    </Col>
                </Row>
                <Row style={{marginTop:'5px', fontSize: '0.8rem'}}>
                  <Col style={{width:'100%'}}>
                    <Card className='my-2' color='secondary' outline>
                      <CardHeader>Sourced From <FaPlus style={{color: 'green', right: '10px', position: 'absolute'}} onClick={(e) => this.addNewSource()}/></CardHeader>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col style={{width:'50%', fontWeight: 'bold'}}>Tag</Col>
                            <Col style={{width:'50%',textAlign:'right', fontWeight: 'bold'}}>Net Weight</Col>
                          </Row>
                          {this.state.selectedTag.Sources.map((source, j) => {
                            return <Row key={j} style={{whiteSpace: "nowrap"}}>
                              <Col style={{width:'50%', fontWeight:'normal'}}>
                                <Select
                                  options={this.state.availableTags}
                                  onChange={(values) => {
                                    if (values != null && values.length > 0) {
                                      var value = values[0].value;
                                      var selectedTag = this.state.selectedTag;
                                      selectedTag.Sources[j].Tag = value;
                                      this.setState({selectedTag: selectedTag});
                                      }
                                  }}/>
                              </Col>
                              <Col style={{width:'50%', textAlign:'right', fontWeight:'normal'}}>
                                <input type="text" className='form-control' style={{fontSize: '0.8rem'}} value={source.Weight} maxLength="15" onChange={(e) => {
                                  var selectedTag = this.state.selectedTag;
                                  selectedTag.Sources[j].Weight = Number.parseInt(e.target.value);
                                  this.setState({selectedTag: selectedTag});
                                }}/>                                
                              </Col>
                            </Row>
                          })}                        
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => this.handleClose() }
                    color='primary'
                    style={{ flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                    Cancel
                </Button>
                <Button
                        color='primary'
                        onClick={() => this.addAsset()}
                        style={{ flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}
                    >
                    Create Tag
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={this.state.showUpdate} onHide={(e) => this.handleClose('update')} dialogClassName="inventory-modal">
            <Modal.Header > 
                <Modal.Title>Update Tag Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                  <Col style={{width: '50%'}}>
                    <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Status</label>
                  </Col>
                </Row>
                <Row style={{marginBottom: 10}}>
                  <Col style={{width: '100%'}}>
                    <Select
                      options={this.state.tagTypeList}
                      values={this.state.tagTypeList.filter(option => option.value === this.state.selectedTag.Status)}
                      onChange={(value) => this.updateStatus(value)} />
                  </Col>
                </Row>
                <Row>
                    <Col style={{width: '50%', fontWeight: 'bold'}}>
                        <label style={{ fontSize: '0.8rem' }}>Tag</label>
                    </Col>
                    <Col style={{width: '50%', fontWeight: 'bold'}}>
                        <label style={{ fontSize: '0.8rem' }}>Commodity</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                    <input type="text" className="form-control" style={{ fontSize: '0.8rem' }} value={this.state.selectedTag.Tag} onChange={(e) => this.updateTag(e)} />
                    </Col>
                    <Col style={{width: '50%'}}>
                      <Select
                        options={this.state.commodityList}
                        values={this.state.commodityList.filter(option => option.value === this.state.selectedTag.Commodity)}
                        onChange={(value) => this.updateCommodity(value)} />
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Origin</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Destination</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                    <Select
                      options={this.state.siteList}
                      values={this.state.siteList.filter(option => option.value === this.state.selectedTag.From)}
                      onChange={(value) => this.updateFrom(value)} />
                    </Col>
                    <Col style={{width: '50%'}}>
                    <Select
                      options={this.state.siteList}
                      values={this.state.siteList.filter(option => option.value === this.state.selectedTag.To)}
                      onChange={(value) => this.updateTo(value)} />
                      {/* <input type="text" className="form-control" style={{ fontSize: '0.8rem' }} value={this.state.selectedTag.To} onChange={(e) => this.updateTo(e)}/> */}
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Date</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Net Weight (lbs)</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                      <input type="date" className="form-control" style={{ fontSize: '0.8rem' }} value={this.state.selectedTag.TransDate} onChange={(e) => this.updateDate(e)}/>
                    </Col>
                    <Col style={{width: '50%'}}>
                      <input type="text" className="form-control" style={{ fontSize: '0.8rem' }} value={this.state.selectedTag.Weight} onChange={(e) => this.updateWeight(e)}/>
                    </Col>
                </Row>
                <Row style={{marginTop:'5px', fontSize: '0.8rem'}}>
                  <Col style={{width:'100%'}}>
                    <Card className='my-2' color='secondary' outline>
                      <CardHeader>Sourced From <FaPlus style={{color: 'green', right: '10px', position: 'absolute'}} onClick={(e) => this.addNewSource()}/></CardHeader>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col style={{width:'50%', fontWeight: 'bold'}}>Tag</Col>
                            <Col style={{width:'50%',textAlign:'right', fontWeight: 'bold'}}>Net Weight</Col>
                          </Row>
                          {this.state.selectedTag.Sources.map((source, j) => {
                            return <Row key={j} style={{whiteSpace: "nowrap"}}>
                              <Col style={{width:'50%', fontWeight:'normal'}}>
                                <Select
                                  options={this.state.availableTags}
                                  onChange={(values) => {
                                    if (values != null && values.length > 0) {
                                      var value = values[0].value;
                                      var selectedTag = this.state.selectedTag;
                                      selectedTag.Sources[j].Tag = value;
                                      this.setState({selectedTag: selectedTag});
                                      }
                                  }}/>
                              </Col>
                              <Col style={{width:'50%', textAlign:'right', fontWeight:'normal'}}>
                                <input type="text" className='form-control' style={{fontSize: '0.8rem'}} value={source.Weight} maxLength="15" onChange={(e) => {
                                  var selectedTag = this.state.selectedTag;
                                  selectedTag.Sources[j].Weight = Number.parseInt(e.target.value);
                                  this.setState({selectedTag: selectedTag});
                                }}/>                                
                              </Col>
                            </Row>
                          })}                        
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => this.handleClose() }
                    color='primary'
                    style={{ flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                    Cancel
                </Button>
                <Button
                  color='primary'
                  onClick={() => this.updateAsset()}
                  style={{ flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                    Update Tag
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={this.state.showDelete} onHide={(e) => this.handleClose('delete')} dialogClassName="inventory-modal">
            <Modal.Header > 
                <Modal.Title>Delete Tag Information</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                  <Col style={{width: '50%'}}>
                    <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Status</label>
                  </Col>
                </Row>
                <Row style={{marginBottom: 10}}>
                  <Col style={{width: '100%'}}>
                    <label>{this.state.selectedTag.Status}</label>
                  </Col>
                </Row>
                <Row>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Tag</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Commodity</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                      <label>{this.state.selectedTag.Tag}</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                      <label>{this.state.selectedTag.Commodity}</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Origin</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Destination</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                      <label>{this.state.selectedTag.From}</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                      <label>{this.state.selectedTag.To}</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '10px'}}>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Date</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                        <label style={{ fontSize: '0.8rem', fontWeight: 'bold' }}>Net Weight (lbs)</label>
                    </Col>
                </Row>
                <Row style={{marginTop: '5px'}}>
                    <Col style={{width: '50%'}}>
                      <label>{this.state.selectedTag.TransDate}</label>
                    </Col>
                    <Col style={{width: '50%'}}>
                      <label>{this.state.selectedTag.Weight}</label>
                    </Col>
                </Row>
                <Row style={{marginTop:'5px', fontSize: '0.8rem'}}>
                  <Col style={{width:'100%'}}>
                    <Card className='my-2' color='secondary' outline>
                      <CardHeader>Sourced From</CardHeader>
                      <CardBody>
                        <Container>
                          <Row>
                            <Col style={{width:'50%', fontWeight: 'bold'}}>Tag</Col>
                            <Col style={{width:'50%',textAlign:'right', fontWeight: 'bold'}}>Net Weight</Col>
                          </Row>
                          {this.state.selectedTag.Sources.map((source, j) => {
                            return <Row key={j} style={{whiteSpace: "nowrap"}}>
                              <Col style={{width:'50%', fontWeight:'normal'}}>
                                <label>{source.Tag}</label>
                              </Col>
                              <Col style={{width:'50%', textAlign:'right', fontWeight:'normal'}}>
                                <label>{source.Weight}</label>
                              </Col>
                            </Row>
                          })}                        
                        </Container>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => this.handleClose() }
                    color='primary'
                    style={{ flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                    Cancel
                </Button>
                <Button
                  color='red'
                  onClick={() => this.deleteAsset()}
                  style={{ flex: '1', marginTop: '20px', whiteSpace: 'nowrap', padding: '6px 30px', marginRight: '5px', height: '38px' }}>
                    Delete Tag
                </Button>
            </Modal.Footer>
        </Modal>                
      </div>
    );
  }
}
