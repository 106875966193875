const url = 'https://smblockchain.shapirometals.com:8443';
// const url = 'http://13.84.152.205:8080';

const ApiFunctions = {
    getAllAssetsAsync: async function() {
        const response = await fetch(url + '/api/assets', {
            headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();
        return data;
    },
    getAssetHistoryAsync: async function(tag) {
        const response = await fetch(url + '/api/history/' + tag, {
            headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();
        return data;
    },
    postAssetAddAsync: async function(asset) {
        const response = await fetch(url + '/api/addasset', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(asset),
        });

        const data = await response.json();
        return data;
    },
    postAssetUpdateAsync: async function(asset) {
        const response = await fetch(url + '/api/updateasset', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(asset),
        });

        const data = await response.json();
        return data;
    },
    getAssetDeleteAsync: async function(tag) {
        const response = await fetch(url + '/api/delete/' + tag, {
            headers: { 'Content-Type': 'application/json' }
        });

        const data = await response.json();
        return data;
    }
}

export default ApiFunctions;