const url = 'https://smblockchain.shapirometals.com:8443';
// const url = 'http://13.84.152.205:8080';

const AuthFunctions = {
    getAuthToken: function() {
        return localStorage.getItem('token');
    },
    login: async function(username, password) {
        const response = await fetch(url + '/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({username: username, password: password}),
        });

        const data = await response.json();

        return data;
    },
    logout: function() {
        localStorage.removeItem('token');
    }
}

export default AuthFunctions;